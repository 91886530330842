import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import axios from "../../utils/axiosConfig";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    width: "425px",
  },
}));

export default function CustomModal(props) {
  const classes = useStyles();
  const { onSubmit, examId } = props;

  useEffect(() => {
    // Ensure beforeunload is not set, so no alert is shown
    window.onbeforeunload = null;

    return () => {
      // Cleanup beforeunload when component unmounts
      window.onbeforeunload = null;
    };
  }, []);

  const handleLogout = async () => {
    try {
      console.log("userexamid", examId);
      const response = await axios.get(`/test/retest/${examId}`);
      onSubmit("998");
    } catch (error) {
      console.log("Failed to logout", error);
    }
  };

  return (
    <Modal
      disableEnforceFocus={true}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={true}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        <div className={classes.paper} style={{ textAlign: "justify" }}>
          <div style={{ textAlign: "center" }}>
            <h3 id="transition-modal-title" style={{ color: "red", fontWeight: "bold", marginBottom: "30px" }}>
              Low bandwidth detected
            </h3>
          </div>

          <div className="form-group" style={{ textAlign: "center" }}>
            <p style={{ fontWeight: "bold" }}>We encountered low internet connectivity while saving your test. Your responses were not recorded.</p>
            <p>
              To complete your assessment, please reattempt the test. Click the button below to be redirected to the login page, where you can sign in
              again to reattempt your test.
            </p>

            <ul className="list-inline">
              <li>
                <Link to="/v2/user/logout" onClick={handleLogout} className="btn btn-blue" style={{ borderRadius: "10px" }}>
                  <strong>Close</strong>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
