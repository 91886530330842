import React, { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import axios from "../utils/axiosConfig";
import resultAxios from "../utils/resultAxiosConfig";
import { useSelector, useDispatch } from "react-redux";
import Link from "@material-ui/core/Link";
// import Button from '@material-ui/core/Button';

import AppBar from "../components/AppBar";
import Drawer from "../components/Drawer";
import DashboardGrid from "../components/DashboardGrid";
import FeedbackDashboardGrid from "../components/FeedbackDashboardGrid";
import CompletedDashboardGrid from "../components/CompletedDashboardGrid";
import ExpiredDashboardGrid from "../components/ExpiredDashboardGrid";
import AdminDashboardGrid from "../components/AdminDashboardGrid";
import SearchUserDetails from "./SearchUserDetails";
import LoadingSkeleton from "../components/LoadingSkeleton";
// import ReactModal from 'react-modal';
import Test from "./Test";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";

import Support from "./Support";
import SiteRequirement from "./SiteRequirement";
import Practice from "./Practice";
import GenerateList from "./GenerateList";
import Cookies from "js-cookie";
import {
  setButtonArray,
  getButtonArray,
  addUserHome,
  getLoading,
  setGroupDetails,
  getGroupDetails,
  setLoading,
  setOrgId,
  getOrgId,
  getTypeButton,
  setTypeButton,
  setUserHomeDetails,
  getUserHomeDetails,
  setType,
  getType,
  setSelectedType,
  getSelectedType,
  setTestData,
  getTestData,
  setOrgData,
} from "../reducer/dashboardSlice";
import { localhostConstants, serverConstants } from "../utils/serverConstants";

// import useUnload from '../components/UseUnload';
// import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  overlay: {
    overflow: "inherit",
    position: "initial",
    padding: "0px",
    inset: "40px",
    border: "1px solid rgb(204, 204, 204)",
    background: "rgb(255, 255, 255)",
    borderRadius: "4px",
    outline: "none",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [typeValue, setType] = React.useState([]);
  // const [buttonArray, setButtonArray] = React.useState([]);
  const [pending, setPending] = React.useState([]);
  const [started, setStarted] = React.useState([]);
  const [buttonName, setButtonClicked] = React.useState("");
  // const [typeButton, setTypeButton] = React.useState('');
  const [selectTypeButton, setSelectedType] = React.useState("");
  const [showModal, setModal] = React.useState(false);
  const [testData, setTestData] = React.useState([]);
  const [adminData, setAdminData] = React.useState(0);
  const [tableDataHere, setTableData] = React.useState([]);
  const [isActiveTableLoading, setIsActiveTableLoading] = React.useState(false);
  const [tableGenerateDataHere, setGenerateTableData] = React.useState([]);
  const [isGenerateTableLoading, setIsGenerateTableLoading] = React.useState(false);
  const [examId, setExamId] = React.useState("");
  const [menuTitle, setMenuTitle] = React.useState(["Tests", "Completed", "Expired"]);
  const [cssNav, setCssNav] = React.useState(["", "", "", "", "", "", "", ""]);
  const [selectedTab, setSelectedTab] = React.useState("");
  const [processData, setProcessData] = React.useState(true);
  const [codeLanguages, setCodeLanguages] = React.useState([]);
  const loading = useSelector(getLoading);
  const groupDetails = useSelector(getGroupDetails);
  const typeButtonData = useSelector(getTypeButton);
  const userHomeData = useSelector(getUserHomeDetails);
  const orgId = useSelector(getOrgId);
  const buttonArray = useSelector(getButtonArray);
  let token = Cookies.get("token");
  //get testData function for handleChange

  React.useEffect(() => {
    axios
      .get("/test/getUserOrgData", {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        if (Cookies.get("role") !== "ROLE_SUPERADMIN") {
          leftPanelMenu(
            response.data.result.length > 0 ? response.data.result[0].selectedMenu : typeButtonData,
            response.data.result.length > 0 ? response.data.result[0].orgId : orgId
          );
        }
        if (response.data.result.length > 0) {
          dispatch(setTypeButton(response.data.result[0].typeButton));
          setSelectedTab(response.data.result[0].selectedMenu);
          dispatch(setOrgId(response.data.result[0].orgId));
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const leftPanelMenu = async (typeButton, organizationId) => {
    let data = {
      newValue: typeButton,
      org: organizationId ? organizationId : orgId,
    };
    await dispatch(addUserHome(data))
      .then((response) => {
        dispatch(setLoading());
        dispatch(setUserHomeDetails(response.payload));
        dispatch(setGroupDetails(response.payload.groups));
        let typeData = response.payload;
        handleHome(typeData, data.newValue);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleHome = (typeData, typeButton) => {
    let testType = [];
    testType.push("Tests");
    testType.push("Feedback");
    testType.push("Survey");
    testType.push("WorkFlow");
    testType.push("Site Requirements");
    testType.push("Support");
    // testType.push("User Help");
    setType(testType);
    dispatch(setTypeButton(typeButton === "" ? (selectedTab !== "" ? selectedTab : testType[0]) : typeButton));
    // setTypeButton(typeButton === "" ? testType[0] : typeButton);
    let testData = typeData.groups.TEST;
    dispatch(setButtonArray(typeData.groups));
    dispatch(setOrgId(typeData.latestOrg));
    dispatch(setOrgData(typeData.org));
    let pendingArray = [],
      startedArray = [];

    Object.keys(testData).map((item) => {
      pendingArray.push(testData[item][0]["000"]);
      startedArray.push(testData[item][0]["001"]);
    });
    setPending(pendingArray);
    setStarted(startedArray);
  };

  useEffect(() => {}, [showModal]);

  useEffect(() => {
    if (userHomeData) {
      handleHome(userHomeData, typeButtonData);
    }
  }, [userHomeData, typeButtonData]);

  useEffect(() => {
    if (Cookies.get("role") === "ROLE_SUPERADMIN") {
      var testType = [];
      testType.push("Ongoing Tests");
      testType.push("Pending Results");
      testType.push("Regenerate Results");
      setType(testType);
      dispatch(setTypeButton(typeButtonData === "" ? testType[0] : typeButtonData));
      {
        try {
          setIsActiveTableLoading(true);
          axios
            .get("/test/getActiveUsers", {
              headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              // setTableData(Object.entries(response.data.result));
              if (response.data.result) {
                let tableDataValue = Object.entries(response.data.result);
                let arrActiveUSers = tableDataValue.filter((item) => item[1].activeTest);
                setTableData(arrActiveUSers);
                setAdminData(response.data.totalUsers);
                setIsActiveTableLoading(false);
              } else {
                setTableData([]);
              }
            });
        } catch (error) {
          console.log("ERROR: " + error);
        }
      }
      {
        try {
          setIsGenerateTableLoading(true);
          axios
            .post("/test/regeneratelisting", {
              headers: {
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ${token}`,
              },
            })
            .then((response) => {
              // setTableData(Object.entries(response.data.result));
              if (response.data) {
                setGenerateTableData(response.data.data);
                setIsGenerateTableLoading(false);
                // let tableDataValue = Object.entries(response.data.result);
                // let arrActiveUSers = tableDataValue.filter(item => item[1].activeTest);
                // setTableData(arrActiveUSers)
                // setAdminData(response.data.totalUsers)
              } else {
                setGenerateTableData([]);
              }
            });
        } catch (error) {
          console.log("ERROR: " + error);
        }
      }
    }
  }, [token]);

  // useeffect to submit test if present in localstorage
  useEffect(() => {
    processPendingTestSubmissions();
  }, []);

  const processPendingTestSubmissions = () => {
    let remainingData = getLocalStorageKeysByPrefix("test-submit");

    if (remainingData.length > 0) {
      for (let i in remainingData) {
        const bodyData = JSON.parse(localStorage.getItem(remainingData[i]));

        resultAxios
          .post(`/result/submittest/${bodyData.userExamId}`, bodyData, {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then((response) => {
            if (response.status == 200) {
              localStorage.removeItem(remainingData[i]);
            }
          })
          .catch((error) => {
            console.log("Failed to send the test for submission");
          });
      }
    }
  };

  function getLocalStorageKeysByPrefix(prefix) {
    const matchingKeys = [];

    // Iterate over all keys in localStorage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Check if the key starts with the desired prefix
      if (key.startsWith(prefix)) {
        matchingKeys.push(key);
      }
    }

    console.log(matchingKeys);

    return matchingKeys;
  }

  const [open, setOpen] = React.useState(true);
  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  const closeModal = () => {
    setModal(false);
    window.location.reload(false);
  };
  const handleDownlaodReport = (data) => {
    setProcessData(false);
    const adminBackendUrl = serverConstants.find((item) => window.location.href.includes(item.urlUniqueString))
      ? serverConstants.find((item) => window.location.href.includes(item.urlUniqueString)).adminBackendURL
      : localhostConstants.adminBackendURL;
    try {
      axios
        .post(
          adminBackendUrl + "/api/auth/login",
          { username: "padmin@icicilombard.com", password: "Admin@2025" },
          { headers: { "Content-Type": "application/json" } }
        )
        .then((response) => {
          //  console.log(response.data)
          axios
            .get(adminBackendUrl + `/api/users/group-report/${Cookies.get("userId")}/${data.groupId}`, {
              responseType: "arraybuffer",
              headers: { "Content-Type": "application/json", Accept: "application/pdf", Authorization: response.data.token },
            })
            .then((response) => {
              //Create a Blob from the PDF Stream
              setProcessData(true);
              const pdfBlob = new Blob([response.data], { type: "application/pdf" });
              const blobUrl = window.URL.createObjectURL(pdfBlob);
              const link = document.createElement("a");
              link.href = blobUrl;
              const addName = Cookies.get("userName") + "-" + Date.now() + ".pdf";
              link.setAttribute("download", addName);
              link.click();
              link.remove();
              URL.revokeObjectURL(blobUrl);
            })
            .catch((error) => {
              console.log("AXIOS ERROR: " + error);
            });
        })
        .catch((error) => {
          console.log("AXIOS ERROR: " + error);
        });

      // axios.get('https://download.assesshub.com/v3/download/pdf-report',
      //  {
      //   responseType: 'blob',
      //   headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     'Authorization' : `Bearer ${token}`,
      //     'groupId':data.groupId
      //   }
      // })
      //   .then((response )=> {
      //     //Create a Blob from the PDF Stream
      //     setProcessData(true)
      //     const pdfBlob = new Blob([response.data], { type: "application/pdf" })
      //     const blobUrl = window.URL.createObjectURL(pdfBlob)
      //     const link = document.createElement('a')
      //     link.href = blobUrl
      //     const addName = Cookies.get('userName') + '-' + Date.now() + '.pdf'
      //     link.setAttribute('download', addName)
      //     link.click();
      //     link.remove();
      //     URL.revokeObjectURL(blobUrl);
      //     })
      //   .catch((error)=> {
      //     console.log("AXIOS ERROR: "+error);
      //   })
    } catch (error) {
      console.log("ERROR: " + error);
    }
  };

  //   useUnload(e => {
  //     debugger;
  //     //e.preventDefault();
  //.consle.log('Window close event===>');
  //     //alert("Window closed");
  //     let browseRefresh = Cookies.get('login');
  //.consle.log('browseRefresh value===>', browseRefresh);
  //     if(browseRefresh === null){

  //     axios.get("/v3/login/logout", {
  //       headers: {
  //         'Access-Control-Allow-Origin': '*',
  //       }
  //     }).then(userData => {

  //       if(userData.data.status === 'Logout'){
  //.consle.log('value ====>successful logout');

  //         Cookies.set('token', '', { path: '/' });//expires: 7,
  //        props.setUser(false);
  // //        props.history.push('/v2/user/');
  //         // window.location.href='http://192.168.31.35:3000/v2/user';
  //         // window.location.reload();
  //       }
  //     }).catch(error => {
  //.consle.log('userData error===>', error);//, error.response.data
  //     });
  //   }
  //   });

  //     return(
  //         <div className={classes.root}>
  //           <div id='switchw'></div>
  //           {!showModal ?
  //           <>
  //             <CssBaseline />
  //             <AppBar open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}
  //             themeType={props.themeType} changeTheme={props.changeTheme} history={props.history} />
  //             <Drawer open={open} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}
  //             testType={typeValue} setTypeButton={setTypeButton} setSelectedType={setSelectedType} />
  //             {(typeButton === 'Tests' || typeButton === 'Survey') ?
  //               <DashboardGrid pending={pending} started={started} buttonArray={buttonArray}
  //               buttonName={buttonName} setButtonClicked={setButtonClicked} typeButton={typeButton}
  //               selectTypeButton={selectTypeButton} setSelectedType={setSelectedType}
  //               setModal={setModal} setTestData={setTestData} setExamId={setExamId} />
  //             :
  //               (typeButton === 'Feedback')
  //               ?
  //                 <FeedbackDashboardGrid pending={pending} started={started} buttonArray={buttonArray}
  //                 buttonName={buttonName} setButtonClicked={setButtonClicked} typeButton={typeButton}
  //                 selectTypeButton={selectTypeButton} setSelectedType={setSelectedType} />

  //               :
  //               (typeButton === 'Completed')
  //               ?
  //                 <CompletedDashboardGrid pending={pending} started={started} buttonArray={buttonArray}
  //                 buttonName={buttonName} setButtonClicked={setButtonClicked} typeButton={typeButton}
  //                 selectTypeButton={selectTypeButton} setSelectedType={setSelectedType} />
  //               :
  //               (typeButton === 'Expired')
  //               ?
  //                 <ExpiredDashboardGrid pending={pending} started={started} buttonArray={buttonArray}
  //                 buttonName={buttonName} setButtonClicked={setButtonClicked} typeButton={typeButton}
  //                 selectTypeButton={selectTypeButton} setSelectedType={setSelectedType} />
  //               :
  //               (typeButton === 'Support' || typeButton === 'User Help')
  //               ?
  //                 <Support typeButton={typeButton} />
  //               :
  //               (typeButton === 'Site Requirements')
  //               ?
  //                 <SiteRequirement typeButton={typeButton} />
  //               :
  //               (typeButton === 'Practice')
  //               ?
  //                 <Practice pending={pending} started={started} buttonArray={buttonArray}
  //                 buttonName={buttonName} setButtonClicked={setButtonClicked} typeButton={typeButton}
  //                 selectTypeButton={selectTypeButton} setSelectedType={setSelectedType}
  //                 setModal={setModal} setTestData={setTestData} setExamId={setExamId} />
  //               :
  //                 <></>
  //             }
  //           </>
  //           :
  //           <Dialog fullScreen open={showModal} onClose={closeModal} TransitionComponent={Transition}>

  //           {/* <ReactModal
  //           isOpen={showModal}
  //           contentLabel="User Exam"
  //           ariaHideApp={false}

  //           className={classes.overlay}
  //           style={{
  //             backgroundColor: (props.themeType === 'light') ? 'red' : '#FFF'

  //           }}
  //         > */}
  //           <Test themeType={props.themeType} changeTheme={props.changeTheme}
  //           closeModal={closeModal} testData={testData} examId={examId} />
  //         {/* </ReactModal> */}
  //         </Dialog>
  //         }

  //         </div>
  //     );
  // }
  const handleIconCLick = (index, text) => {
    dispatch(setTypeButton(text));
    setSelectedTab(text);
    setCssNav(["", "", "", "", "", "", "", ""]);
    let a = ["", "", "", "", "", "", "", ""];
    a[index] = classes.active;
    setCssNav(a);
    let data = {
      typeButton: "",
      newValue: "",
    };
    if (text === "Tests") {
      data = {
        typeButton: text,
        newValue: "Tests",
        org: orgId,
      };
      dispatch(setTypeButton(data.typeButton));
      setSelectedTab(data.newValue);
    } else if (text === "Feedback") {
      data = {
        typeButton: text,
        newValue: "Feedback",
        org: orgId,
      };
      dispatch(setTypeButton(data.typeButton));
      setSelectedTab(data.newValue);
    } else if (text === "Site Requirements" || text === "Support") {
      data = {
        typeButton: text,
        newValue: "",
        org: orgId,
      };
      dispatch(setTypeButton(data.typeButton));
      setSelectedTab(data.newValue);
    } else if (text === "WorkFlow") {
      data = {
        typeButton: text,
        newValue: "WorkFlow",
        org: orgId,
      };
      dispatch(setTypeButton(data.typeButton));
      setSelectedTab(data.newValue);
    } else {
      data = {
        typeButton: text,
        newValue: "Survey",
        org: orgId,
      };
      dispatch(setTypeButton(data.typeButton));
      setSelectedTab(data.newValue);
    }
    dispatch(setTypeButton(text));
    if (Cookies.get("role") !== "ROLE_SUPERADMIN") {
      leftPanelMenu(text);
    }
    if (text === "User Help") {
      return (
        <Link href="../../docs/ConductOnlineTest.html" target="_blank">
          <a>thesitewizard.com</a>
        </Link>
      );

      //    return (<a href={require('./docs/ConductOnlineTest.html')} target="_blank">thesitewizard.com</a>)
    }
    setSelectedType(
      text === "Tests"
        ? "TEST"
        : text === "Practice"
        ? "PRACTISE"
        : text === "Survey"
        ? "SURVEY"
        : text === "Feedback"
        ? "FEEDBACK"
        : text === "Completed"
        ? "COMPLETED"
        : text === "Expired"
        ? "EXPIRED"
        : text === "WorkFlow"
        ? "WORKFLOW"
        : ""
    );
  };

  return (
    <div id="main-body-two" className={classes.root}>
      <div id="switchw"></div>
      {!showModal ? (
        <>
          <CssBaseline />
          <AppBar
            open={open}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            themeType={props.themeType}
            changeTheme={props.changeTheme}
            history={props.history}
          />
          <Drawer
            dimensions={dimensions}
            typeButton={typeButtonData}
            handleIconCLick={handleIconCLick}
            open={open}
            leftPanelMenu={leftPanelMenu}
            handleDrawerOpen={handleDrawerOpen}
            handleDrawerClose={handleDrawerClose}
            testType={typeValue}
            setTypeButton={setTypeButton}
            dispatch={dispatch}
            setSelectedType={setSelectedType}
          />
          {typeButtonData === "Tests" || typeButtonData === "Survey" || typeButtonData === "WorkFlow" ? (
            <DashboardGrid
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setMenuTitle={setMenuTitle}
              menuTitle={menuTitle}
              handleIconCLick={handleIconCLick}
              setLoading={setLoading}
              dispatch={dispatch}
              loading={loading}
              handleHome={handleHome}
              pending={pending}
              started={started}
              buttonArray={buttonArray}
              buttonName={buttonName}
              setButtonClicked={setButtonClicked}
              typeButton={typeButtonData}
              selectTypeButton={selectTypeButton}
              setSelectedType={setSelectedType}
              setModal={setModal}
              setTestData={setTestData}
              setExamId={setExamId}
              examId={examId}
              setCodeLanguages={setCodeLanguages}
            />
          ) : typeButtonData === "Feedback" ? (
            <FeedbackDashboardGrid
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setMenuTitle={setMenuTitle}
              menuTitle={menuTitle}
              showModal={showModal}
              setLoading={setLoading}
              setExamId={setExamId}
              handleHome={handleHome}
              pending={pending}
              started={started}
              buttonArray={buttonArray}
              buttonName={buttonName}
              setButtonClicked={setButtonClicked}
              typeButton={typeButtonData}
              setModal={setModal}
              selectTypeButton={selectTypeButton}
              setTestData={setTestData}
              dispatch={dispatch}
              setSelectedType={setSelectedType}
              examId={examId}
              loading={loading}
              setCodeLanguages={setCodeLanguages}
            />
          ) : typeButtonData === "Completed" ||
            typeButtonData === "FeedbackCompleted" ||
            typeButtonData === "SurveyCompleted" ||
            typeButtonData === "WorkFlowCompleted" ? (
            <CompletedDashboardGrid
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setMenuTitle={setMenuTitle}
              menuTitle={menuTitle}
              handleIconCLick={handleIconCLick}
              setLoading={setLoading}
              dispatch={dispatch}
              loading={loading}
              handleHome={handleHome}
              pending={pending}
              handleDownlaodReport={handleDownlaodReport}
              process={processData}
              started={started}
              buttonArray={buttonArray}
              buttonName={buttonName}
              setButtonClicked={setButtonClicked}
              typeButton={typeButtonData}
              selectTypeButton={selectTypeButton}
              setSelectedType={setSelectedType}
            />
          ) : typeButtonData === "Expired" ||
            typeButtonData === "FeedbackExpired" ||
            typeButtonData === "SurveyExpired" ||
            typeButtonData === "WorkFlowExpired" ? (
            <ExpiredDashboardGrid
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              setMenuTitle={setMenuTitle}
              menuTitle={menuTitle}
              handleIconCLick={handleIconCLick}
              setLoading={setLoading}
              dispatch={dispatch}
              loading={loading}
              handleHome={handleHome}
              pending={pending}
              started={started}
              buttonArray={buttonArray}
              buttonName={buttonName}
              setButtonClicked={setButtonClicked}
              typeButton={typeButtonData}
              selectTypeButton={selectTypeButton}
              setSelectedType={setSelectedType}
            />
          ) : typeButtonData === "Support" || typeButtonData === "User Help" ? (
            <Support typeButton={typeButtonData} />
          ) : typeButtonData === "Site Requirements" ? (
            <SiteRequirement handleHome={handleHome} typeButton={typeButtonData} />
          ) : typeButtonData === "Practice" ? (
            <Practice
              pending={pending}
              started={started}
              buttonArray={buttonArray}
              buttonName={buttonName}
              setButtonClicked={setButtonClicked}
              typeButton={typeButtonData}
              selectTypeButton={selectTypeButton}
              dispatch={dispatch}
              setSelectedType={setSelectedType}
              setModal={setModal}
              setTestData={setTestData}
              setExamId={setExamId}
            />
          ) : typeButtonData === "Ongoing Tests" ? (
            <AdminDashboardGrid adminData={adminData} tableData={tableDataHere} isActiveTableLoading={isActiveTableLoading} />
          ) : typeButtonData === "Pending Results" ? (
            <GenerateList tableData={tableGenerateDataHere} isGenerateTableLoading={isGenerateTableLoading} />
          ) : typeButtonData === "Regenerate Results" ? (
            <SearchUserDetails
              buttonName={buttonName}
              setButtonClicked={setButtonClicked}
              // handleDownlaodReport={handleDownlaodReport} process={processData}
            />
          ) : (
            <></>
          )}
        </>
      ) : (
        <Dialog PaperProps={{ style: { background: "#e5e5e9" } }} fullScreen open={showModal} onClose={closeModal} TransitionComponent={Transition}>
          <Test
            themeType={props.themeType}
            changeTheme={props.changeTheme}
            closeModal={closeModal}
            testData={testData}
            examId={examId}
            setTestData={setTestData}
            codeLanguages={codeLanguages}
          />
        </Dialog>
      )}
    </div>
  );
};
