import React, {useState, useEffect} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import axios from './../utils/axiosConfig';
import { useCookies } from 'react-cookie';
import Cookies from 'js-cookie';
import ReactLogo from '../Primary_site_logo.jpg';
import { WEB_URL } from '../utils/constants';
import axiosOrig from "axios";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href={WEB_URL}>
        Assesshub
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: "url(./../images/login-page.svg)",
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[50],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide(props) {
  const classes = useStyles();
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  let [errorMsg, setError] = useState("");
  const [cookies, setCookie] = useCookies(['token']);

  
  
  let token = Cookies.get('token');

  React.useEffect(async () => {
    // const res = await axiosOrig.get("https://api.ipify.org/?format=json");
    // // console.log(res.data);

    // if (res.data.ip) {
      axios.get("/login/logout", {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // ip: res.data.ip,
          }
      }).then(userData => {
        sessionStorage.clear()
        if(userData.data.status === 'Logout'){
          
          
        Cookies.set('token', '', { path: '/' });//expires: 7, 
        Cookies.set('login', '', { path: '/' });
        Cookies.set("sessionTime",'1',{ path: '/' })
        Cookies.set("org",'',{ path: '/' })
        props.setUser(false);
  //        props.history.push('/v2/user/');
          // window.location.href='http://192.168.31.35:3000/v2/user';
          window.location.href='/v2/user/login';
          // window.location.reload();
        }
      }).catch(error => {
        //, error.response.data
      });
    // }
  },[])

  

  return (
    <>

    </>
  );
}