import React from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import cache from 'memory-cache';

// import Grid from '@material-ui/core/Grid';
// import Button from '@material-ui/core/Button';

import PopOverPopUpState from './PopOverPopUpState';
// import d from 'final-form-arrays';



const useStyles = makeStyles(theme => ({
    root: {
        display: "flex"
    },
    buttonGroup: {
        '& > *': {
            margin: theme.spacing(1),
        }
    }
}));

export default (props) => {
    // const classes = useStyles();
    // const theme = useTheme();

    const { showModel,showThankyou, webProctoring, progress, setProgress, questionChange, showSubmit, setFinishModal, setFinishModalDetail, questionNo, setQuestionNo, checkFlag, setClearFlag, testData, setCheckFlag, examId, setQuestionChange, clearFlag, mandatory, navFlag, selectedOption, examDetail, addNewQuestion, disable, setDisable, setLastQuestionModal, isRecording, setIsRecording, isVideoSent, setIsVideoSent} = props;

    const [hour, setHour] = React.useState(0);
    const [minutes, setMinutes] = React.useState(0);
    const [second, setSecond] = React.useState(0);
    const [totalTimeSpend,setTotalTimeSpend] = React.useState(testData[questionNo -1].timeSpend ? testData[questionNo -1].timeSpend : 0);
    const [disableFinish, setDisableFinish] = React.useState(false)

    // Disable the buttons if recording is in progress in qtypecode===1 Auto speech Evaluation
    const isDisabled = isRecording && (examDetail[0].examtypeid === 1 || examDetail[0].examtypeid === 3);
    const isVideoDisabled = (isRecording || isVideoSent) && (examDetail[0].examtypeid === 1 || examDetail[0].examtypeid === 3);

    let timespend = testData[questionNo - 1].timeSpend;

    React.useEffect(() => {
        if(!showModel){
        setTimeout(() => {
            if (questionChange) {

                setQuestionChange(false);
            }
            timespend++;
            setTotalTimeSpend(timespend);
            testData[questionNo - 1].timeSpend = timespend;
            let totalSeconds = timespend;
            setHour(Math.floor(totalSeconds / 3600));
            //totalSeconds %= 3600;
            setMinutes(Math.floor(totalSeconds / 60));
            setSecond(totalSeconds % 60);
        }, 1000);
        }
    });

    const checkIfAttempted = (ind) => {
        if (testData[ind].qtypecode === '12' || testData[ind].qtypecode === '4' || testData[ind].qtypecode === '5' || testData[ind].qtypecode === '6' || testData[ind].qtypecode === '7' || testData[ind].qtypecode === '8' || testData[ind].qtypecode === '9' || testData[ind].qtypecode === '19' || testData[ind].qtypecode === '20' || testData[ind].qtypecode === '29') {
            if (testData[ind].descriptiveText && testData[ind].descriptiveText.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "") != '') {
                return true
            }
            else {
                return false
            }
        } else if (testData[ind].qtypecode === '1' || testData[ind].qtypecode === '2' || testData[ind].qtypecode === '3' || testData[ind].qtypecode === '13' || testData[ind].qtypecode === '16' || testData[ind].qtypecode === '18' || testData[ind].qtypecode === '14' || testData[ind].qtypecode === '24' || testData[ind].qtypecode === '32' || testData[ind].qtypecode === '17') {
            if (testData[ind].selectedChoiceId === null || testData[ind].selectedChoiceId === "") {
                return false
            }
            else {
                return true
            }
        } else if (testData[ind].qtypecode === '28') {
            if (testData[ind].answerResourcePath === undefined || testData[ind].answerResourcePath === null || testData[ind].answerResourcePath === "") {
                return false
            }
            else {
                return true
            }
        } else if (testData[ind].qtypecode === '27') {
            if (testData[ind].answerResourcePath === undefined || testData[ind].answerResourcePath === null || testData[ind].answerResourcePath === "") {
                return false
            }
            else {
                return true
            }
        }
    }


    // const disable = navFlag && (testData[questionNo -1].selectedChoiceId === '');
    //
    React.useEffect(() => {
        // setTimeout(() => {
        //
        testData[questionNo - 1].qtypecode === '28' || testData[questionNo - 1].qtypecode === '20' || testData[questionNo - 1].qtypecode === '26' || testData[questionNo - 1].qtypecode === '12' || testData[questionNo - 1].qtypecode === '6' || testData[questionNo -1].qtypecode === '5' ?
            setDisable((questionNo === testData.length) || (!navFlag && (testData[questionNo - 1].descriptiveText === null))) :
            testData[questionNo - 1].qtypecode === '21' || testData[questionNo - 1].qtypecode === '14' ?
                setDisable((questionNo === testData.length) || false) ://!navFlag && !(testData[questionNo -1].selectedChoiceId)
                testData[questionNo - 1].qtypecode === '2' || testData[questionNo - 1].qtypecode === '13' || testData[questionNo - 1].qtypecode === '1' ? setDisable((questionNo === testData.length) || (!navFlag && (testData[questionNo - 1].selectedChoiceId === "null" || testData[questionNo - 1].selectedChoiceId === "" || testData[questionNo - 1].selectedChoiceId === null || !testData[questionNo - 1].selectedChoiceId.match(/\d+/g)))) :
                    setDisable((questionNo === testData.length) || (!navFlag));//(questionNo === testData.length) ||

        // setCheckFlag(true);
        //},1000);
        if(examDetail[0].examtypeid !== 4){
            if ((selectedOption === '' || selectedOption === null || selectedOption === "null") && questionNo === testData.length) {
                setLastQuestionModal(true)
            }
        }else{
            if((examDetail[0].totalmarkstoattempt - testData.reduce((a, b) => a + b['qmarks'], 0)) === 0 && (selectedOption === '' || selectedOption === null || selectedOption === "null") && questionNo === testData.length){
                setLastQuestionModal(true)
            }
        }

        testData[questionNo - 1].qtypecode === '25' ?
        testData[questionNo - 1].buttonStyle = (testData[questionNo - 1].uploadedFile === null) ? 'Not Answered' : 'Answered' :
        testData[questionNo - 1].qtypecode === '28' || testData[questionNo - 1].qtypecode === '20' || testData[questionNo - 1].qtypecode === '26' || testData[questionNo - 1].qtypecode === '12' || testData[questionNo - 1].qtypecode === '6' || testData[questionNo -1].qtypecode === '5' ?
            testData[questionNo - 1].buttonStyle = (testData[questionNo - 1].descriptiveText === null) ? 'Not Answered' : 'Answered' :
            testData[questionNo - 1].buttonStyle = (testData[questionNo - 1].selectedChoiceId === '' || testData[questionNo - 1].selectedChoiceId === null || testData[questionNo - 1].selectedChoiceId === "null") ? 'Not Answered' : 'Answered';

        setCheckFlag(checkIfAttempted(questionNo - 1))

    }, [disable, selectedOption, questionNo])

    React.useEffect(() => {
        let isAttempted = true
        for (let i in testData) {
            if (checkIfAttempted(i) === false) {
                isAttempted = false
                setDisableFinish(true)
                break
            }
        }
        if (isAttempted === true) {
            setDisableFinish(false)
        }
    })

    const clearResponse = () => {
        //
        setClearFlag(true);
        setCheckFlag(false);

    }

    const onNext = () => {
        document.getElementById("main-body").scrollIntoView( {behavior: "smooth" });

        testData[questionNo - 1].qtypecode === '25' ?
        testData[questionNo - 1].buttonStyle = (testData[questionNo - 1].uploadedFile === null) ? 'Not Answered' : 'Answered' :
        testData[questionNo - 1].qtypecode === '28' || testData[questionNo - 1].qtypecode === '20' || testData[questionNo - 1].qtypecode === '26' || testData[questionNo - 1].qtypecode === '12' || testData[questionNo - 1].qtypecode === '6' || testData[questionNo - 1].qtypecode === '27' ||  testData[questionNo - 1].qtypecode === '5' ?
            testData[questionNo - 1].buttonStyle = (testData[questionNo - 1].descriptiveText === null) ? 'Not Answered' : 'Answered' :
            testData[questionNo - 1].buttonStyle = (testData[questionNo - 1].selectedChoiceId === '' || testData[questionNo - 1].selectedChoiceId === null || testData[questionNo - 1].selectedChoiceId === "null") ? 'Not Answered' : 'Answered'

        let collectData = {quesData: testData, examDetail: examDetail}
        let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
        window.localStorage.setItem(examId, encode);
        if(examDetail[0].examtypeid === 4){
            addNewQuestion();
        }
        if (questionNo === testData.length) {
            setLastQuestionModal(true)
            setQuestionNo(questionNo)
        }else{
            setQuestionNo(questionNo + 1);
        }
        setCheckFlag(false)
        setQuestionChange(true);

        var totalAnswered = testData.filter((v, i) => testData[i].buttonStyle == 'Answered').length
        var totalQuestion = testData.length

        setProgress((totalAnswered / totalQuestion) * 100)
    }

    const onMarkReview = () => {
        testData[questionNo - 1].buttonStyle = 'Marked Review';
        let collectData = {quesData: testData, examDetail: examDetail}
        let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
        window.localStorage.setItem(examId, encode);

        if(questionNo === testData.length){
            setQuestionNo(questionNo)
        }else{
            setQuestionNo(questionNo + 1);
        }
        setQuestionChange(true);
    }

    const onPrevious = () => {
        document.getElementById("main-body").scrollIntoView( {behavior: "smooth" });

        testData[questionNo - 1].qtypecode === '25' ?
        testData[questionNo - 1].buttonStyle = (testData[questionNo - 1].uploadedFile === null) ? 'Not Answered' : 'Answered' :
        testData[questionNo - 1].qtypecode === '28' || testData[questionNo - 1].qtypecode === '20' || testData[questionNo - 1].qtypecode === '26' || testData[questionNo - 1].qtypecode === '12' || testData[questionNo - 1].qtypecode === '6' || testData[questionNo - 1].qtypecode === '5' ?
        testData[questionNo - 1].buttonStyle = (testData[questionNo - 1].descriptiveText === null) ? 'Not Answered' : 'Answered' : testData[questionNo - 1].buttonStyle = (testData[questionNo - 1].selectedChoiceId === '' || testData[questionNo - 1].selectedChoiceId === null || testData[questionNo - 1].selectedChoiceId === "null") ? 'Not Answered' : 'Answered'
        let collectData = {quesData: testData, examDetail: examDetail}
        let encode = encodeURIComponent(btoa(encodeURIComponent(JSON.stringify(collectData))))
        window.localStorage.setItem(examId, encode);
        if(questionNo !== 1){
            setQuestionNo(questionNo - 1)
        }else{
            setQuestionNo(1);
        }
        setQuestionChange(true);
    }

    return (
        <div>
            <div className="question-time">
                <div className="time-text">
                    {/* <p>Time Spent: <span>{`${hour.toString().length === 1 ? '0' + hour : hour} : ${minutes.toString().length === 1 ? '0' + minutes : minutes} : ${second.toString().length === 1 ? '0' + second : second}`}</span></p> */}
                    <p>Time Spent: <span>{new Date(totalTimeSpend * 1000).toISOString().slice(11, 19)}</span></p>
                </div>
                <PopOverPopUpState testData = {testData} questionNo = {questionNo}/>

            </div>
            {/* testing purpose only */}
            {/* <div className="form-group">
					      	<ul className="list-inline btn-list " >
                              {checkFlag === true?
                    <strong> checkFlag = true |</strong>
                    :
                    <strong> checkFlag = false |</strong>

                }


            <li>
                {mandatory === true ?
                    <strong> Is_mandatory = true  |</strong>
                    :
                    <strong> Is_mandatory = false |</strong>

                }

            </li>
            <li >
            {navFlag === true ?
                    <strong> navigation_allowed = true |</strong>
                    :
                    <strong> navigation_allowed = false |</strong>

                }
                {webProctoring ?
                    <strong> web Proctoring = true |</strong>
                    :
                    <strong> web Proctoring = false |</strong>

                }
                 {showThankyou ?
                    <strong> showThankyou = true |</strong>
                    :
                    <strong> showThankyou= false |</strong>

                }

            </li>
           	</ul>
					    </div> */}
            {/* -------------------- */}
            <div className="form-group">
                <ul className="list-inline btn-list" >
                    <li><a className="btn btn-blue" id="pbtn" disabled={isVideoDisabled || isDisabled || (/*mandatory === true ? true :*/ navFlag === false ? true : false) || (testData[questionNo -1].index === 0 ? true : false)} onClick={() => onPrevious()} >
                        <strong>Previous</strong>
                    </a></li>
                    <li>
                        <a className="btn btn-blue" id="pbtn"

                        // check flag marks if question is attempted
                        
                        disabled={ examDetail[0].examtypeid !== 4 ? ( isVideoDisabled || isDisabled || (mandatory === true && checkFlag === false) || (testData.length === questionNo ? true : false)) : ((examDetail[0].totalmarkstoattempt - testData.reduce((a, b) => a + b['qmarks'], 0 ) === 0) ? true : false)}
                        onClick={() => onNext()}>
                            <strong>Next</strong>
                        </a></li>
                    {/* <li className="btn-finish" >
                        <a id="pbtn" type="submit" className="btn btn-finish button-text"
                            variant="contained"
                            disabled={mandatory === true && checkFlag === false ? true : examDetail[0].examtypeid === 4 ? !showSubmit :
                                (!navFlag ? !(questionNo === testData.length && (testData[questionNo - 1].selectedChoiceId !== '' || testData[questionNo - 1].descriptiveText !== ''
                                )) : false)} onClick={() => setFinishModalDetail(true)}
                        ><strong>Finish</strong></a></li> */}

                    {/* <li><a className="btn btn-blue button-text" style={{background:"#D5D5D5"}} id="pbtn" disabled={!navFlag} onClick={() => onMarkReview() } >
                <strong>Review & Next</strong>
            </a></li>
                <li><a className="btn btn-blue button-text" style={{background:"#D5D5D5"}} id="pbtn" disabled={testData[questionNo - 1].qtypecode === '28' || testData[questionNo - 1].qtypecode === '13'} onClick={clearResponse}>
                <strong>Clear Response</strong>
                 </a></li> */}
                    <li className="btn-finish" >
                        <a id="pbtn" className="btn btn-finish" variant="contained"
                            // disabled={examDetail[0].examtypeid !== 4 ? (mandatory === true && (testData[questionNo - 1].descriptiveText && testData[questionNo - 1].descriptiveText.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, "") === "") && checkFlag === false ? true : examDetail[0].examtypeid === 4 ? !showSubmit : (!navFlag ? !(questionNo === testData.length && (testData[questionNo - 1].selectedChoiceId !== '' || testData[questionNo - 1].descriptiveText !== null)) : false)) : ((examDetail[0].totalmarkstoattempt - testData.reduce((a, b) => a + b['qmarks'], 0) === 0) ? false : true)}
                            disabled={examDetail[0].examtypeid !== 4 ? isDisabled || isVideoDisabled || (mandatory === true && disableFinish === true) : ((examDetail[0].totalmarkstoattempt - testData.reduce((a, b) => a + b['qmarks'], 0) === 0) ? false : true)}
                            onClick={() => setFinishModalDetail(true)}
                        ><strong>Finish</strong></a>
                    </li>
                </ul>
            </div>
        </div>
    );
}