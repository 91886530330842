import React, { useState, useRef, useEffect } from 'react';
import { Button, Typography, LinearProgress, Grid, Paper } from '@material-ui/core';
import {
  Mic as MicIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';

const AudioTest = ({ onTestComplete }) => {
  const [micTestStatus, setMicTestStatus] = useState('idle');
  const [audioLevel, setAudioLevel] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [showContinueButton, setShowContinueButton] = useState(false);
  const [testDuration, setTestDuration] = useState(0);

  const audioContext = useRef(null);
  const mediaStream = useRef(null);
  const analyser = useRef(null);
  const animationFrame = useRef(null);
  const isTestingRef = useRef(false);
  const testStartTime = useRef(null);
  const noSoundTimeout = useRef(null);

  const cleanupAudioTest = () => {
    isTestingRef.current = false;
    if (animationFrame.current) {
      cancelAnimationFrame(animationFrame.current);
      animationFrame.current = null;
    }
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
      mediaStream.current = null;
    }
    if (audioContext.current) {
      audioContext.current.close();
      audioContext.current = null;
    }
    if (noSoundTimeout.current) {
      clearTimeout(noSoundTimeout.current);
      noSoundTimeout.current = null;
    }
  };

  const startAudioTest = async () => {
    try {
      setMicTestStatus('testing');
      setErrorMessage('');
      setAudioLevel(0);
      setShowContinueButton(false);
      isTestingRef.current = true;
      testStartTime.current = Date.now();

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;

      audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
      analyser.current = audioContext.current.createAnalyser();
      const source = audioContext.current.createMediaStreamSource(stream);
      source.connect(analyser.current);

      analyser.current.fftSize = 256;
      const bufferLength = analyser.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      let maxLevelDetected = 0;

      // Set a timeout to check if no sound is detected after 5 seconds
      noSoundTimeout.current = setTimeout(() => {
        if (isTestingRef.current && maxLevelDetected < 30) {
          setMicTestStatus('failed');
          setErrorMessage('No sound detected. Please check if:\n\n' +
            '1. Your microphone is properly connected\n' +
            '2. You have selected the correct microphone in your system settings\n' +
            '3. You are speaking or making sound near the microphone\n' +
            '4. Your microphone is not muted in your system settings'
          );
          cleanupAudioTest();
        }
      }, 5000);

      const checkAudioLevel = () => {
        if (!isTestingRef.current) return;

        const currentDuration = (Date.now() - testStartTime.current) / 1000;
        setTestDuration(currentDuration);

        analyser.current.getByteFrequencyData(dataArray);
        const average = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
        const normalizedLevel = Math.min(100, (average / 128) * 100);
        setAudioLevel(normalizedLevel);

        maxLevelDetected = Math.max(maxLevelDetected, normalizedLevel);

        if (normalizedLevel > 30) {
          if (noSoundTimeout.current) {
            clearTimeout(noSoundTimeout.current);
            noSoundTimeout.current = null;
          }
          setMicTestStatus('success');
          setShowContinueButton(true);
          cleanupAudioTest();
          return;
        }

        animationFrame.current = requestAnimationFrame(checkAudioLevel);
      };

      checkAudioLevel();
    } catch (error) {
      setMicTestStatus('failed');
      if (error.name === 'NotAllowedError') {
        setErrorMessage(
          'Microphone access denied. To fix this:\n\n' +
          '1. Click the camera icon in your browser\'s address bar\n' +
          '2. Select "Allow" for microphone access\n' +
          '3. Refresh the page and try again'
        );
      } else if (error.name === 'NotFoundError') {
        setErrorMessage(
          'No microphone found. To fix this:\n\n' +
          '1. Connect a microphone to your device\n' +
          '2. Make sure it\'s properly plugged in\n' +
          '3. Try using a different USB port\n' +
          '4. Check if the microphone appears in your system settings'
        );
      } else {
        setErrorMessage(
          'Microphone error. Please try:\n\n' +
          '1. Checking your microphone connection\n' +
          '2. Restarting your browser\n' +
          '3. Using a different microphone\n' +
          '4. Updating your audio drivers'
        );
      }
      cleanupAudioTest();
    }
  };

  const handleRetest = () => {
    cleanupAudioTest();
    setAudioLevel(0);
    setMicTestStatus('idle');
    setShowContinueButton(false);
    setTestDuration(0);
    startAudioTest();
  };

  const handleContinue = () => {
    onTestComplete(true);
  };

  useEffect(() => {
    return () => {
      cleanupAudioTest();
    };
  }, []);

  const renderMicVisualization = () => {
    const pulseSize = 20 + audioLevel * 0.3;

    return (
      <div style={{
        position: 'relative',
        width: '200px',
        height: '200px',
        margin: '2rem auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {micTestStatus === 'testing' && (
          <>
            <div style={{
              position: 'absolute',
              width: `${pulseSize}%`,
              height: `${pulseSize}%`,
              borderRadius: '50%',
              border: '2px solid #3f51b5',
              opacity: 0.3,
              animation: 'pulse 2s infinite',
            }} />
            <div style={{
              position: 'absolute',
              width: `${pulseSize - 10}%`,
              height: `${pulseSize - 10}%`,
              borderRadius: '50%',
              border: '2px solid #3f51b5',
              opacity: 0.2,
              animation: 'pulse 2s infinite 0.5s',
            }} />
          </>
        )}

        <Paper elevation={3} style={{
          width: '100px',
          height: '100px',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: micTestStatus === 'success' ? '#4caf50' :
                         micTestStatus === 'failed' ? '#f44336' : '#fff',
        }}>
          {micTestStatus === 'success' ? (
            <CheckCircleIcon style={{ fontSize: '50px', color: '#fff' }} />
          ) : micTestStatus === 'failed' ? (
            <CancelIcon style={{ fontSize: '50px', color: '#fff' }} />
          ) : (
            <MicIcon style={{
              fontSize: '50px',
              color: micTestStatus === 'testing' ? '#3f51b5' : '#757575',
              animation: micTestStatus === 'testing' ? 'fadeInOut 1s infinite' : 'none',
            }} />
          )}
        </Paper>
      </div>
    );
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        {renderMicVisualization()}
      </Grid>

      <Grid item xs={12} style={{ textAlign: 'center' }}>
        {micTestStatus === 'testing' && (
          <div style={{ margin: '1rem' }}>
            <LinearProgress
              variant="determinate"
              value={audioLevel}
              style={{
                height: '10px',
                borderRadius: '5px',
                marginBottom: '0.5rem',
              }}
            />
            <Typography variant="caption">
              Audio Level: {Math.round(audioLevel)}%
            </Typography>
          </div>
        )}

        {micTestStatus === 'failed' && (
          <Typography
            style={{
              margin: '1rem',
              color: '#f44336',
              whiteSpace: 'pre-line',
              textAlign: 'left',
              backgroundColor: '#ffebee',
              padding: '16px',
              borderRadius: '4px'
            }}
          >
            {errorMessage}
          </Typography>
        )}

        {micTestStatus === 'success' && (
          <Typography style={{ color: '#4caf50', margin: '1rem' }}>
            Microphone is working properly!
          </Typography>
        )}

        <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
          {micTestStatus === 'idle' && (
            <Button
              variant="contained"
              color="primary"
              onClick={startAudioTest}
            >
              Test Microphone
            </Button>
          )}

          {(micTestStatus === 'failed' || micTestStatus === 'success') && (
            <Button
              variant="outlined"
              color="primary"
              onClick={handleRetest}
            >
              Test Again
            </Button>
          )}

          {showContinueButton && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleContinue}
            >
              Continue to Camera Test
            </Button>
          )}
        </div>
      </Grid>

      <style jsx>{`
        @keyframes pulse {
          0% { transform: scale(0.95); opacity: 0.5; }
          50% { transform: scale(1.05); opacity: 0.3; }
          100% { transform: scale(0.95); opacity: 0.5; }
        }

        @keyframes fadeInOut {
          0% { opacity: 1; }
          50% { opacity: 0.5; }
          100% { opacity: 1; }
        }
      `}</style>
    </Grid>
  );
};

export default AudioTest;