import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import axios from "../../utils/axiosConfig";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import * as faceapi from "face-api.js";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Webcam from "react-webcam";
import msgConstants from "../../utils/messages";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, Typography, CircularProgress } from "@material-ui/core";
import VideocamIcon from "@material-ui/icons/Videocam";
import MicIcon from "@material-ui/icons/Mic";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import Slide from "@material-ui/core/Slide";
import { proctoringURL } from "../../utils/constants";
import noMicrophone from "./../../assets/noMic.png";
// import facedetect from './../../assets/facedetect.png';
import { Class } from "@material-ui/icons";
import MediaTestModal from "../MediaTestModal";
import AudioTest from "../MediaTestModal";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden", // Hides both horizontal and vertical overflow
    margin: 0, // Resets the default margin
    minWidth: "100%", // Ensures that the body is at least as wide as the viewport
    minHeight: "100%", // Ensures that the body is at least as tall as the viewport
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: "15px",
    width: "380px",
  },
  wrapper: {
    display: "flex",
    height: "100%", // Set a specific height
    width: "100%",
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(8), // Adjust left margin for smaller screens
      marginRight: theme.spacing(4), // Adjust right margin for smaller screens
    },
  },
  Errwrapper: {
    display: "flex",
    height: "100%", // Set a specific height
    width: "100%",
    // border: '1px solid #ccc',
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(8), // Adjust left margin for smaller screens
      marginRight: theme.spacing(4), // Adjust right margin for smaller screens
    },
  },
  leftColumn: {
    [theme.breakpoints.down("md")]: {
      // Adjust width for medium-sized screens
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  rightColumn: {
    flex: 1,
    marginRight: theme.spacing(8),

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  TopRightColumn: {
    flex: 1,
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "15px",

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
    },
  },
  ErrleftColumn: {
    flex: 1,
    marginLeft: theme.spacing(13),

    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      // border: '1px solid #ccc',
    },
  },
  ErrrightColumn: {
    flex: 1,
    paddingLeft: theme.spacing(20),
    paddingRight: theme.spacing(15),

    [theme.breakpoints.down("sm")]: {
      marginRight: 0,
      // border: '1px solid #ccc',
    },
  },

  leftside: {
    alignItems: "center",
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(6),
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(6),
    },

    // border: '1px solid #ccc',
  },
  background1: {
    backgroundColor: "#BAD3DD",
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    width: "80%", // Set a specific width
  },
  background2: {
    backgroundColor: "#BAD3DD",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: "70%", // Set a specific width
    [theme.breakpoints.down("md")]: {
      width: "90%", // Adjust width for medium-sized screens
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%", // Adjust width for small screens
      margin: 0,
    },
  },
  webbox: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
      margin: 0,
    },
  },
  box: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      width: "90%", // Adjust width for medium-sized screens
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%", // Adjust width for small screens
      margin: 0,
    },
    // border: '1px solid #ccc',
  },
  Errbox: {
    backgroundColor: "#F2FFD4",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    marginRight: theme.spacing(8),
    marginLeft: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
    border: "2px solid #CCFF54",
  },
  setup: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
      margin: 0,
    },
    // border: '1px solid #ccc',
  },
  rightsetup: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(5),
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(5),
      margin: 0,
    },
  },
  Camera: {
    marginTop: theme.spacing(1),
  },
  WebCAM: {
    marginTop: theme.spacing(1),
  },
  errWebCAM: {
    marginLeft: theme.spacing(12),
  },
  errWebCAMPara: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    fontSize: "14px",
    wordSpacing: "2px",
    lineHeight: "1.5",
  },
  audiobottombutton: {
    marginTop: theme.spacing(2),
    // border: '1px solid #ccc',
  },
  captureBTN: {
    marginRight: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      margin: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(1),
      margin: 0,
    },
  },
  BTN: {
    width: "90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  accessmic: {
    marginLeft: theme.spacing(4),
    width: "80%",
  },
  browser: {
    color: "#51869B",
  },
  waitMSG: {
    textAlign: "center",
    fontSize: "22px",
    marginTop: theme.spacing(40),
  },
  clickAgain: {
    marginLeft: theme.spacing(20),
  },
  clickAgainTXT: {
    marginTop: theme.spacing(20),
    // border: '1px solid #ccc',
    marginLeft: theme.spacing(4),
    width: "100%",
  },
  weberror: {
    backgroundColor: "#CC0000",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "40%",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: "#ffffff",
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "80%", // Adjust the width for smaller screens
    },
  },
  micerror: {
    backgroundColor: "#CC0000",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "40%",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    color: "#ffffff",
    borderRadius: "20px",
    [theme.breakpoints.down("sm")]: {
      width: "80%", // Adjust the width for smaller screens
    },
  },
  img: {
    marginLeft: theme.spacing(1),
  },
  status: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(14),
  },
  leftstatus: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(20),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2),
      marginRight: 0,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginRight: 0,
    },
  },
  noMicContainer: {
    [theme.breakpoints.down("md")]: {
      width: "50%", // Adjust width for small screens
      height: "50%", // Allow height to adjust based on width
      marginTop: theme.spacing(5), // Adjust margin for small screens
      marginLeft: "auto",
      // Center the image on small screens
    },
  },
  nomic: {
    textAlign: "center",
    marginTop: "4vh", // Adjust as needed
  },
  micimage: {
    marginTop: "10vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      width: "70%", // Adjust width for small screens
      height: "auto", // Allow height to adjust based on width
      marginTop: "5vh", // Adjust margin for small screens
      marginLeft: "auto",
      marginRight: "auto", // Center the image on small screens
    },
  },
  detectImageContainer: {
    marginTop: theme.spacing(7),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  detectImage: {
    maxWidth: "100%", // Ensure the image does not exceed its container's width
    maxHeight: "400px", // Limit the image height
  },
  camscreen: {
    width: "100%",
    height: "auto",
    maxWidth: 550, // Set a maximum width to avoid stretching on large screens
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%", // Adjust for smaller screens
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function ImagEProctoringCapture(props) {
  const classes = useStyles();
  const webcamRef = useRef(null);

  let token = Cookies.get("token");
  const modalRef = useRef();
  const { examDetail, hidden, setHidden, resumeTest, MonitoringData } = props;
  const closeInstruction = () => {
    window.location.reload(false);
  };

  let conf = 0.5;

  const [snapShow, setSnapShow] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [countFace, setCountFace] = React.useState(0);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [capturedImage, setCapturedImage] = React.useState(null);
  const [inCaptureMode, setInCaptureMode] = React.useState(false);
  const [showProceedButton, setShowProceedButton] = React.useState(false);
  const [facedetected, setfacedetected] = React.useState(false);
  const [ClickAgain, setClickAgain] = React.useState(false);
  const [ShowClickAgainButton, setShowClickAgainButton] = React.useState(false);
  const [StartCapture, setStartCapture] = React.useState(true);
  const [ShowCaptureImageButton, setShowCaptureImageButton] = React.useState(false);
  const [webcamReady, setWebcamReady] = React.useState(false); // Add state to track webcam readiness
  const [WaitingMessage, setWaitingMessage] = React.useState(null);
  const [Nofaces, setNofaces] = React.useState(false);
  const [Multiplefaces, setMultiplefaces] = React.useState(false);
  const [WebCamError, setWebCamError] = React.useState(false);
  const [MicError, setMicError] = React.useState(false);
  const [Closed, setClosed] = React.useState(false);
  const [MediaErrorModal, setMediaErrorModal] = React.useState(true);
  const [CameraTroubleshoot, setCameraTroubleshoot] = React.useState(false);
  const [MicTroubleshoot, setMicTroubleshoot] = React.useState(false);
  const [videoTestStatus, setVideoTestStatus] = useState("idle"); // 'idle', 'testing', 'success', 'failed'
  const [processingStep, setProcessingStep] = useState(0);
  const [videoProcessing, setVideoProcessing] = useState(false);
  const [micTestStatus, setMicTestStatus] = useState("idle"); // 'idle', 'testing', 'success', 'failed'
  const [audioLevel, setAudioLevel] = useState(0);
  const [testingStage, setTestingStage] = useState("mic");
  const [processing, setProcessing] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };
  if (modalOpen && examDetail[0].monitoringflag && MonitoringData.result.speaker_dialect) {
    const audioConstraints = {
      audio: {
        sampleRate: 44100, // Adjust the sample rate as needed
        noiseReduction: true, // Enable noise reduction
        gain: 1.2, // Adjust gain level (between 0 and 5)
        // echoCancellation: true,
        // noiseSuppression: true,
        // autoGainControl: true,
        // sampleRate: 44100, // Adjust the sample rate as needed
      },
    };
    navigator.mediaDevices
      .getUserMedia(audioConstraints)
      .then((stream) => {
        const recorder = new MediaRecorder(stream);
      })
      .catch((e) => setMicError(true));
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return false;
    }
    setModal(false);
  };
  const startCapture = () => {
    setModalOpen(true);
  };
  const reduceImageSize = async (imageSrc) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set the canvas dimensions to the image dimensions
        canvas.width = img.width;
        canvas.height = img.height;

        // Draw the image onto the canvas
        ctx.drawImage(img, 0, 0);

        // Convert the canvas image to a lower quality or size
        const reducedImage = canvas.toDataURL("image/jpeg", 0.8); // Example quality set to 0.5

        resolve(reducedImage);
      };
      img.src = imageSrc;
    });
  };
  const captureImage = async () => {
    // Show processing UI immediately
    setProcessing(true);
    // Hide any "click again" button while processing
    setShowClickAgainButton(false);
    if (ClickAgain === false) {
      setWaitingMessage(msgConstants.WAIT_MSG);
    }
    // Capture image from webcam
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    // Run face detection (simulate AI processing)
    let { detections } = await detectFaces(imageSrc);
    if (detections.length === 0) {
      setMultiplefaces(false);
      setNofaces(true);
    }
    if (detections.length >= 2) {
      setNofaces(false);
      setMultiplefaces(true);
    }
    if (detections.length === 1) {
      // If exactly one face is detected, proceed:
      setfacedetected(true);
      setShowProceedButton(true);
      setStartCapture(false);
      // Optionally, you can close the modal here:
      closeModal();
      localStorage.setItem("primaryimage", imageSrc);
      const primaryimage = await reduceImageSize(imageSrc);
      // Call your backend API to save the image.
      await axios
        .post("/test/savePrimaryImage", {
          headers: { "Access-Control-Allow-Origin": "*" },
          userexamid: examDetail[0].id,
          instanceid: examDetail[0].instanceid,
          imagePath: primaryimage,
        })
        .then((resp) => {
          if (resp.data.msg === "SUCCESS") {
            setHidden(true);
          } else {
            setHidden(false);
          }
        })
        .catch((error) => {
          console.error("Error saving image:", error);
          setHidden(false);
        });
    }
    if (detections.length === 0 || detections.length >= 2) {
      setWaitingMessage(null);
      setClickAgain(true);
      setShowClickAgainButton(true);
      setHidden(false);
    }
    // Remove the processing UI once processing is complete.
    setProcessing(false);
  };
  const clickAgain = async () => {
    await setCapturedImage(null);
    setClickAgain(false);
    setShowCaptureImageButton(true);
  };
  const proceed = () => {
    setHidden(true);
    setMessage(msgConstants.PROCEED_MSG);
    setModal(true);
    setCountFace(0);
    resumeTest();
  };
  const closeModal = () => {
    setClosed(true);
    setModalOpen(false);
    setCapturedImage(null);
  };

  const saveImage = () => {
    setInCaptureMode(true);
    setModalOpen(true);
  };
  const detectFaces = async (imageSrc) => {
    return new Promise(async (resolve) => {
      const img = new Image();
      img.src = imageSrc;
      const detections = await faceapi
        .detectAllFaces(img, new faceapi.TinyFaceDetectorOptions({ conf }))
        .withFaceLandmarks()
        .withFaceDescriptors()
        .withFaceExpressions();
      resolve({ detections: detections });
    });
  };

  useEffect(() => {
    // for production
    /*const loadModels = async () => {
        Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri('https://user.assesshub.com/v2/models'),
          faceapi.nets.faceLandmark68Net.loadFromUri('https://user.assesshub.com/v2/models'),
          faceapi.nets.faceRecognitionNet.loadFromUri('https://user.assesshub.com/v2/models'),
          faceapi.nets.faceExpressionNet.loadFromUri('https://user.assesshub.com/v2/models'),
          faceapi.nets.ageGenderNet.loadFromUri('https://user.assesshub.com/v2/models')
          ])
      };
      loadModels();
      */

    // for staging
    /*const loadModels = async () => {
        Promise.all([
          faceapi.nets.tinyFaceDetector.loadFromUri('https://staging-user.assesshub.com/v2/models'),
          faceapi.nets.faceLandmark68Net.loadFromUri('https://staging-user.assesshub.com/v2/models'),
          faceapi.nets.faceRecognitionNet.loadFromUri('https://staging-user.assesshub.com/v2/models'),
          faceapi.nets.faceExpressionNet.loadFromUri('https://staging-user.assesshub.com/v2/models'),
          faceapi.nets.ageGenderNet.loadFromUri('https://staging-user.assesshub.com/v2/models')
          ])
      };
      loadModels();
      */

    //for localhost
    const loadModels = async () => {
      Promise.all([
        faceapi.nets.tinyFaceDetector.loadFromUri(proctoringURL + "/models"),
        faceapi.nets.faceLandmark68Net.loadFromUri(proctoringURL + "/models"),
        faceapi.nets.faceRecognitionNet.loadFromUri(proctoringURL + "/models"),
        faceapi.nets.faceExpressionNet.loadFromUri(proctoringURL + "/models"),
        faceapi.nets.ageGenderNet.loadFromUri(proctoringURL + "/models"),
      ]);
    };
    loadModels();

    if (webcamRef.current) {
      setWebcamReady(true);
    }
  }, [webcamRef]);

  const handleUserMediaError = (err) => {
    setWebCamError(true);
  };

  const onCameraLoad = () => {
    setShowCaptureImageButton(true);
  };
  const goback = () => {
    window.onbeforeunload = function () {
      return null; // return null to avoid pop up
    };

    window.location.reload("/dashboard");
  };
  const onConfirm = () => {
    setMediaErrorModal(false);
  };
  const onCamTroubleshoot = () => {
    setCameraTroubleshoot(true);
  };
  const onMicTroubleshoot = () => {
    setMicTroubleshoot(true);
  };

  return (
    <>
      {/* if the the current test is of video test then render other component */}
      {examDetail[0].reporttemplate === "VIDEORPT" ? (
        <div>
          <Grid item xs={12} style={{ textAlign: "center" }}>
            {snapShow == true ? (
              StartCapture === true ? (
                <Button variant="contained" color="primary" onClick={startCapture}>
                  <strong>Start</strong>
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={proceed}>
                  <strong>Proceed further</strong>
                </Button>
              )
            ) : (hidden && examDetail[0].monitoringflag) || true ? (
              <Button variant="contained" style={{ marginLeft: "2rem" }} color="primary" onClick={() => resumeTest()}>
                <strong>{examDetail[0].status === "000" ? "Start" : "Resume"}</strong>
              </Button>
            ) : StartCapture === true ? (
              <Button variant="contained" color="primary" onClick={startCapture}>
                <strong>Start</strong>
              </Button>
            ) : (
              <Button variant="contained" color="primary" onClick={proceed}>
                <strong>Proceed further</strong>
              </Button>
            )}
          </Grid>
          <Dialog fullScreen open={modalOpen} onClose={closeModal} disableBackdropClick disableEscapeKeyDown TransitionComponent={Transition}>
            {/* Main centered container */}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100vh",
                padding: "20px",
              }}
            >
              {/* Content container with fixed width */}
              <div
                style={{
                  width: "100%",
                  maxWidth: "800px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  padding: "32px",
                  border: "solid 1px",
                  borderColor: "#808080",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                }}
              >
                {testingStage === "mic" ? (
                  // Microphone Testing Section
                  <div>
                    <Typography variant="h6" align="center" gutterBottom>
                      <b>Audio setup</b>
                    </Typography>
                    <Typography align="center" style={{ marginBottom: "24px" }}>
                      Microphone access is mandatory for this Assessment.
                      <br />
                      Make sure that your microphone is on throughout the assessment.
                    </Typography>

                    {MonitoringData ? (
                      <AudioTest
                        onTestComplete={(success) => {
                          setMicError(!success);
                          if (success) {
                            setTestingStage("camera");
                          }
                        }}
                      />
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <div style={{ marginBottom: "24px" }}>
                          <strong>There is No need of Microphone for this test</strong>
                        </div>
                        <img
                          src={noMicrophone}
                          style={{
                            maxWidth: "100%",
                            height: "auto",
                            marginBottom: "24px",
                          }}
                        />
                        <Button variant="contained" color="primary" onClick={() => setTestingStage("camera")}>
                          Continue to Camera Setup
                        </Button>
                      </div>
                    )}
                  </div>
                ) : (
                  // Camera Testing Section
                  <div>
                    <Typography variant="h6" align="center" gutterBottom>
                      <b>Camera setup</b>
                    </Typography>
                    <Typography align="center" style={{ marginBottom: "24px" }}>
                      We use camera images to ensure fairness for everyone.
                      <br />
                      Make sure that you are in front of your camera.
                    </Typography>

                    {capturedImage === null && (
                      <div
                        style={{
                          border: "1px solid #e0e0e0",
                          borderRadius: "8px",
                          padding: "24px",
                          marginBottom: "24px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                          }}
                        >
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <VideocamIcon />
                            <Typography variant="h6" style={{ marginLeft: "8px" }}>
                              <b>Camera</b>
                            </Typography>
                            <ArrowRightAltIcon style={{ marginLeft: "8px" }} />
                          </div>
                          <div style={{ display: "flex" }}>
                            <Typography style={{ marginRight: "8px" }}>{!WebCamError ? "Enabled" : "Disabled"}</Typography>
                            {!WebCamError ? <CheckCircleIcon style={{ color: "#00FF00" }} /> : <CancelIcon style={{ color: "#FF0000" }} />}
                          </div>
                        </div>

                        {!WebCamError ? (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "16px",
                            }}
                          >
                            <Webcam
                              ref={webcamRef}
                              onUserMedia={onCameraLoad}
                              onUserMediaError={handleUserMediaError}
                              videoConstraints={{
                                width: 450,
                                height: 300,
                                facingMode: "user",
                              }}
                              screenshotFormat="image/png"
                              style={{
                                width: "100%",
                                maxWidth: "550px",
                                height: "auto",
                              }}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              backgroundColor: "#f5f5f5",
                              padding: "24px",
                              borderRadius: "4px",
                            }}
                          >
                            <Typography variant="h6" gutterBottom>
                              <b>Trouble with your webcam?</b>
                            </Typography>
                            <Typography component="div">
                              <ul
                                style={{
                                  paddingLeft: "20px",
                                  margin: "16px 0",
                                }}
                              >
                                <li>Ensure you have granted permission for your browser to access your camera.</li>
                                <li>
                                  Ensure you are using a <b>supported browser</b>.
                                </li>
                                <li>If you have multiple camera devices, ensure you have given your browser permission to use the right device.</li>
                                <li>Ensure your camera driver and web browser is up to date.</li>
                                <li>Restart your device and try accessing the assessment again.</li>
                              </ul>
                            </Typography>
                          </div>
                        )}
                      </div>
                    )}

                    <div style={{ textAlign: "center" }}>
                      {!WebCamError ? (
                        // --- Display the processing UI while waiting for the image to be analyzed ---
                        processing ? (
                          <div style={{ marginTop: "16px" }}>
                            <CircularProgress />
                            <Typography style={{ marginTop: "8px" }}>Processing image, please wait...</Typography>
                          </div>
                        ) : showProceedButton ? // Optionally render a "Proceed" UI or nothing if processing is done.
                        null : ClickAgain ? (
                          ShowClickAgainButton && (
                            <div>
                              <Typography style={{ marginBottom: "16px" }}>
                                {Nofaces ? <b>{msgConstants.NOFACE_MSG}</b> : Multiplefaces && <b>{msgConstants.MULTIFACE_MSG}</b>}
                              </Typography>
                              <Button variant="contained" color="primary" onClick={clickAgain}>
                                <strong>Click Again</strong>
                              </Button>
                            </div>
                          )
                        ) : (
                          // --- Otherwise show the Capture button ---
                          <Button variant="contained" color="primary" onClick={captureImage}>
                            <strong>Capture Image</strong>
                          </Button>
                        )
                      ) : (
                        <Button variant="contained" color="primary" onClick={onCamTroubleshoot}>
                          <strong>Troubleshoot</strong>
                        </Button>
                      )}
                    </div>

                    {WaitingMessage && (
                      <Typography align="center" style={{ marginTop: "16px", color: "#555" }}>
                        {WaitingMessage}
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Dialog>
        </div>
      ) : (
        <div>
          <div>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              {snapShow == true ? (
                StartCapture === true ? (
                  <Button variant="contained" color="primary" onClick={startCapture}>
                    <strong>Start</strong>
                  </Button>
                ) : (
                  <Button variant="contained" color="primary" onClick={proceed}>
                    <strong>Proceed further</strong>
                  </Button>
                )
              ) : (hidden && examDetail[0].monitoringflag) || true ? (
                <Button variant="contained" style={{ marginLeft: "2rem" }} color="primary" onClick={() => resumeTest()}>
                  <strong>{examDetail[0].status === "000" ? "Start" : "Resume"}</strong>
                </Button>
              ) : StartCapture === true ? (
                <Button variant="contained" color="primary" onClick={startCapture}>
                  <strong>Start</strong>
                </Button>
              ) : (
                <Button variant="contained" color="primary" onClick={proceed}>
                  <strong>Proceed further</strong>
                </Button>
              )}
            </Grid>
            <Dialog fullScreen open={modalOpen} onClose={closeModal} disableBackdropClick disableEscapeKeyDown TransitionComponent={Transition}>
              <Grid>
                {MonitoringData && MonitoringData.result.speaker_dialect && WebCamError && MicError && (
                  <Modal
                    open={MediaErrorModal}
                    disableEnforceFocus={true}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={MediaErrorModal}>
                      <div className={classes.paper} style={{ textAlign: "justify" }}>
                        <h3 id="transition-modal-title" style={{ color: "#6f2981", marginBottom: "30px", textAlign: "center" }}>
                          Media Error
                        </h3>
                        <p id="transition-modal-description">
                          Web cam / Microphone access is mandatory for this Assessment. We regret to inform you that you cannot proceed ahead with the
                          test !
                        </p>
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                          <button className="btn btn-blue" style={{ borderRadius: "10px" }} onClick={onConfirm}>
                            Ok
                          </button>
                        </div>
                      </div>
                    </Fade>
                  </Modal>
                )}
                {!CameraTroubleshoot && !MicTroubleshoot && WaitingMessage === null && (
                  <Grid container rowSpacing={1}>
                    {/* Left Column: Camera Setup */}
                    <Grid item xs={6} className={classes.leftColumn}>
                      <div className={classes.setup}>
                        <Typography variant="h6">
                          <b>Camera setup</b>
                        </Typography>
                        <Typography>
                          <br />
                          We use camera images to ensure fairness for everyone.
                          <br />
                          Make sure that you are in front of your camera.
                        </Typography>
                      </div>
                      {capturedImage === null && (
                        <div className={classes.webbox}>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <VideocamIcon />
                              <Typography variant="h6" style={{ marginLeft: "8px" }}>
                                <b>Camera</b>
                              </Typography>
                              <ArrowRightAltIcon style={{ marginLeft: "8px", marginTop: "2px" }} />
                            </div>
                            {!WebCamError ? (
                              <div className={classes.leftstatus}>
                                <p style={{ marginTop: "17px" }}>Enabled</p>
                                <CheckCircleIcon style={{ marginBottom: "8px", color: "#00FF00" }} />
                              </div>
                            ) : (
                              <div className={classes.leftstatus}>
                                <p style={{ marginTop: "17px" }}>Disabled</p>
                                <CancelIcon style={{ marginBottom: "8px", color: "#FF0000" }} />
                              </div>
                            )}
                          </div>
                          {!WebCamError ? (
                            <div className={classes.WebCAM}>
                              <Webcam
                                ref={webcamRef}
                                onUserMedia={onCameraLoad}
                                onUserMediaError={handleUserMediaError}
                                videoConstraints={{
                                  width: 550,
                                  height: 450,
                                  facingMode: "user",
                                }}
                                screenshotFormat="image/png"
                                className={classes.camscreen}
                              />
                            </div>
                          ) : (
                            <div className={classes.background2}>
                              <Typography className={classes.box}>
                                <Typography variant="h6">
                                  <b>Trouble with your webcam?</b>
                                </Typography>
                                Ensure you have granted permission for your browser to access your camera.
                                <br />
                                Ensure you are using a <b className={classes.browser}>supported browser</b>.
                                <br />
                                If you have multiple camera devices, ensure you have given your browser and our website permission to use the right
                                device.
                                <br />
                                Ensure your camera driver and web browser is up to date.
                                <br />
                                Restart your device and try accessing the assessment again using login credentials shared with you.
                              </Typography>
                            </div>
                          )}
                          {WebCamError && (
                            <p style={{ marginLeft: "20px", marginTop: "12px", marginRight: "20px" }}>
                              To enable access you can check some solutions by clicking on below 'Troubleshoot' button.
                            </p>
                          )}
                        </div>
                      )}

                      {!WebCamError ? (
                        showProceedButton ? (
                          closeModal
                        ) : // <Button variant="contained" color="primary" onClick={handleProceed}>
                        //   <strong>Proceed</strong>
                        // </Button>
                        ClickAgain ? (
                          ShowClickAgainButton && (
                            <div className={classes.clickAgainTXT}>
                              <Typography>
                                <br />
                                <br />
                                {Nofaces ? Nofaces && <b>{msgConstants.NOFACE_MSG}</b> : Multiplefaces && <b>{msgConstants.MULTIFACE_MSG}</b>}
                              </Typography>
                              <div className={classes.captureBTN}>
                                <Button variant="contained" color="primary" onClick={clickAgain}>
                                  <strong>Click Again</strong>
                                </Button>
                              </div>
                            </div>
                          )
                        ) : !MicError ? (
                          ShowCaptureImageButton && (
                            <div className={classes.captureBTN}>
                              <div className={classes.BTN}>
                                <Button className="mt-2" variant="contained" color="primary" onClick={captureImage}>
                                  <strong>Capture Image</strong>
                                </Button>
                              </div>
                            </div>
                          )
                        ) : (
                          <strong className={classes.accessmic}>Please enable access for microphone then you will capture the image.</strong>
                        )
                      ) : (
                        <div className={classes.captureBTN}>
                          <div className={classes.BTN}>
                            <Button className="mt-2" variant="contained" color="primary" onClick={onCamTroubleshoot}>
                              <strong>Troubleshoot</strong>
                            </Button>
                          </div>
                        </div>
                      )}
                    </Grid>
                    {/* Right Column: Paragraphs */}
                    <Grid item xs={6}>
                      {MonitoringData && MonitoringData.result.speaker_dialect ? (
                        <div className={classes.rightsetup}>
                          <Typography variant="h6">
                            <b>Audio setup</b>
                          </Typography>
                          <Typography>
                            <br />
                            We use microphone to ensure fairness for the entire assessment.
                            <br />
                            Make sure that your microphone is on throughout the assessment.
                          </Typography>
                          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <MicIcon />
                              <Typography variant="h6" style={{ marginLeft: "8px" }}>
                                <b>Audio:</b>
                              </Typography>
                              <ArrowRightAltIcon style={{ marginLeft: "8px", marginTop: "2px" }} />
                            </div>
                            {!MicError ? (
                              <div className={classes.leftstatus}>
                                <p style={{ marginTop: "17px" }}>Enabled</p>
                                <CheckCircleIcon style={{ marginBottom: "8px", color: "#00FF00" }} />
                              </div>
                            ) : (
                              <div className={classes.leftstatus}>
                                <p style={{ marginTop: "17px" }}>Disabled</p>
                                <CancelIcon style={{ marginBottom: "8px", color: "#FF0000" }} />
                              </div>
                            )}
                          </div>
                          {MicError ? (
                            <div className={classes.background2}>
                              <Typography className={classes.box}>
                                <Typography variant="h6">
                                  <b>Trouble with your microphone?</b>
                                </Typography>
                                Ensure you have granted permission for your browser to access your microphone.
                                <br />
                                Confirm that you are using a <b className={classes.browser}>supported browser</b>.
                                <br />
                                If you have multiple microphone devices, make sure you have given your browser and our website permission to use the
                                correct microphone.
                                <br />
                                Ensure your microphone driver and web browser is up to date.
                                <br />
                                Restart your device and try accessing the assessment again using login credentials shared with you.
                              </Typography>
                            </div>
                          ) : (
                            <div className={classes.box}>
                              <Typography>
                                {" "}
                                Your microphone is working <strong>perfectly</strong>.{" "}
                              </Typography>
                              {!WebCamError ? (
                                <Typography style={{ width: "80%" }} className={classes.box}>
                                  {" "}
                                  <br />
                                  Just be stable and make sure that your face is <strong>perfectly fitting</strong> in camera and then click on{" "}
                                  <strong>CAPTURE IMAGE</strong> button.{" "}
                                </Typography>
                              ) : (
                                <Typography style={{ width: "80%" }} className={classes.box}>
                                  {" "}
                                  But there is problem with accessing your <strong>webcam</strong>. Please resolve that issue. &#40;you can follow
                                  left side instructions to fix this.&#41;
                                </Typography>
                              )}
                            </div>
                          )}
                          {MicError && (
                            <p style={{ marginLeft: "26px", marginTop: "12px" }}>
                              To enable access you can check some solutions by clicking on below 'Troubleshoot' button.
                            </p>
                          )}
                          {MicError && (
                            <div className={classes.audiobottombutton}>
                              <div className={classes.captureBTN}>
                                <div className={classes.BTN}>
                                  <Button className="mt-2" variant="contained" color="primary" onClick={onMicTroubleshoot}>
                                    <strong>Troubleshoot</strong>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      ) : (
                        <Grid container spacing={0}>
                          <Grid item sm={12}>
                            <div className={classes.nomic}>
                              <strong>There is No need of Microphone for this test</strong>
                            </div>
                          </Grid>
                          <Grid item className={classes.micimage} sm={12}>
                            <img src={noMicrophone} style={{ maxWidth: "100%", height: "auto" }} />
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                )}
                {CameraTroubleshoot && WaitingMessage === null && (
                  <Grid>
                    <Grid item xs={11}>
                      <div>
                        <div className={classes.wrapper}>
                          <div style={{ display: "flex", alignItems: "center" }} className={classes.leftColumn}>
                            <VideocamIcon />
                            <Typography variant="h6" style={{ marginLeft: "8px" }}>
                              <b>Camera:</b>
                            </Typography>
                          </div>
                          <div className={classes.TopRightColumn}>
                            <Button className="mt-2" variant="contained" color="primary" onClick={goback}>
                              <strong>Back</strong>
                            </Button>
                          </div>
                        </div>
                        <div className={classes.Errwrapper}>
                          <div className={classes.ErrleftColumn}>
                            <div className={classes.weberror}>
                              <ErrorOutlineOutlinedIcon />
                              <strong>Error Message: Looks like your webcam is NOT working.</strong>
                            </div>
                            <p>There could be several reasons for this issue:</p>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={10} className={classes.errWebCAM}>
                      <div className={classes.errWebCAMPara}>
                        <p>
                          <strong>Reasons:</strong>
                        </p>
                        <ol>
                          <li>
                            <strong>Blocked Access: </strong>The access to your webcam might be blocked by your browser.
                          </li>
                          <li>
                            <strong>Loose Connection:</strong> Make sure that the camera is securely plugged into your computer or mobile device.
                          </li>
                          <li>
                            <strong>USB Port:</strong> If you have more than one USB port, try plugging the camera into a different one, as the
                            current port might be malfunctioning.
                          </li>
                        </ol>
                        <br />
                        <p>
                          <em>
                            <strong>To resolve &quot;Blocked Access&quot;, follow the steps below :</strong>
                          </em>
                        </p>
                        <ol>
                          <li>
                            Refresh the Page: If access to the webcam is blocked, refresh the&nbsp;page and check if your browser is asking for
                            access. If it does, enable it by clicking on the &quot;Allow&quot; button.
                          </li>
                          <li>
                            Using Lock Icon (Top-Left Corner):
                            <ol>
                              <li>
                                Check the top-left corner of your browser screen. You should see a small lock-like icon before the website name.
                              </li>
                              <li>Click on it, and a dropdown menu will appear. In this menu, look for camera access permissions.</li>
                              <li>Allow camera access for this website. It may look like this: Allow Camera</li>
                              <li>After allowing access, refresh the page by clicking on top-right corner 'BACK' button.</li>
                            </ol>
                          </li>
                          <li>
                            Using Camera Icon (Top-Right Corner):
                            <ol>
                              <li>
                                Check the top-right corner of your browser screen for a camera icon. If it looks like&nbsp;Blocked Camera Icon, it
                                means your camera access is blocked.
                              </li>
                              <li>Click on this camera icon.</li>
                              <li>A dialog box will open, showing camera access permissions. If it&#39;s blocked, please select : Allow Camera.</li>
                              <li>Once access is granted, refresh the page by clicking on top-right corner 'BACK' button.</li>
                            </ol>
                          </li>
                        </ol>
                        <br />
                        <p>
                          <b>
                            If you&#39;ve followed these steps and your webcam is still not working, it might be a hardware issue. In that case,
                            consider using an external webcam or contacting technical support for further assistance.
                          </b>
                        </p>
                      </div>
                    </Grid>
                  </Grid>
                )}
                {MicTroubleshoot && WaitingMessage === null && (
                  <Grid>
                    <Grid item xs={11}>
                      <div>
                        <div className={classes.wrapper}>
                          <div style={{ display: "flex", alignItems: "center" }} className={classes.leftColumn}>
                            <MicIcon />
                            <Typography variant="h6" style={{ marginLeft: "8px" }}>
                              <b>Microphone:</b>
                            </Typography>
                          </div>
                          <div className={classes.TopRightColumn}>
                            <Button className="mt-2" variant="contained" color="primary" onClick={goback}>
                              <strong>Back</strong>
                            </Button>
                          </div>
                        </div>
                        <div className={classes.Errwrapper}>
                          <div className={classes.ErrleftColumn}>
                            <div className={classes.micerror}>
                              <ErrorOutlineOutlinedIcon />
                              <strong>Error Message: Looks like your Microphone is NOT working.</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={10} className={classes.errWebCAM}>
                      <div className={classes.errWebCAMPara}>
                        <p>
                          <strong>Trouble with your microphone?</strong>
                        </p>
                        <ol>
                          <li>
                            <p>
                              <strong>Permission Settings:</strong> Ensure you have granted permission for your browser to access your microphone.
                              Follow these steps:
                            </p>
                            <ul>
                              <li>Click on the lock or information icon in the address bar (usually on the left side).</li>
                              <li>Find the microphone option and make sure it&#39;s set to &quot;Allow&quot; or &quot;Ask (default).&quot;</li>
                            </ul>
                            <br />
                          </li>
                          <li>
                            <p>
                              <strong>Supported Browser:</strong> Confirm that you are using a supported browser for the assessment. We recommend
                              using the latest version of browsers like Google Chrome, Mozilla Firefox, or Microsoft Edge for optimal performance.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Select the Right Microphone:</strong> If you have multiple microphone devices, make sure you have given your
                              browser and our website permission to use the correct microphone. Follow these steps:
                            </p>
                            <ul>
                              <li>Click on the lock or information icon in the address bar.</li>
                              <li>Look for the microphone settings and select the appropriate device.</li>
                            </ul>
                            <br />
                          </li>
                          <li>
                            <p>
                              <strong>Update Microphone Driver:</strong> Ensure your microphone driver is up to date. Outdated drivers can cause
                              compatibility issues. Visit the device manager on your computer and update the microphone driver if necessary.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Browser Update:</strong> Make sure your web browser is up to date. An outdated browser may not fully support the
                              latest features, including microphone access. Check for updates and install them if available.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Restart Your Device:</strong> Sometimes, a simple restart can resolve microphone issues. Restart your computer
                              or device and try accessing the assessment again using the provided login credentials.
                            </p>
                          </li>
                        </ol>
                      </div>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  {WaitingMessage !== null && (
                    <div>
                      <div className={classes.waitMSG}>
                        <strong>{WaitingMessage}</strong>
                      </div>
                      {/* <div className={classes.detectImageContainer}><img  className={classes.detectimage} src={facedetect} height="400"/>
          </div> */}
                    </div>
                  )}
                </Grid>
              </Grid>
            </Dialog>
          </div>
          <Snackbar open={modal} autoHideDuration={2000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity={message !== "Webcam has been configured successfully.  Click on Start / Resume to continue..." ? "error" : "success"}
            >
              {message}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
}
export default ImagEProctoringCapture;
