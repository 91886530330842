import React, { useCallback } from "react";
import Countdown from "react-countdown";

export default function ExamTimer(props) {
  // Destructure necessary props
  const { examDetail, isVideoInterviewTest, isRecordingDone, isVideoUploading, setTimeoutModelShow, setTimeoutModelOneMinuteShow, setIsTimeUp } =
    props;

  // Calculate total exam time in milliseconds
  const totalExamTime = Number(examDetail[0].time_remaining) * 1000 || 0;

  // Safe localStorage update
  const updateLocalStorageTime = useCallback((timeInSeconds) => {
    try {
      localStorage.setItem("remainingTime", timeInSeconds.toString());
    } catch (error) {
      console.warn("Local storage update failed", error);
    }
  }, []);

  // Renderer function for custom countdown display
  const renderer = ({ hours, minutes, seconds, total, completed }) => {
    // Formatting to ensure two-digit display

    // Convert total milliseconds back to seconds
    const currentRemainingTime = Math.floor(total / 1000);

    examDetail[0].time_remaining = currentRemainingTime;

    // localStorage.setItem("remainingTime", Math.floor(total / 1000));
    updateLocalStorageTime(currentRemainingTime);

    const formatTime = (time) => time.toString().padStart(2, "0");

    if (completed) {
      // Exam time is up
      return (
        <>
          <span>Time's up!</span>
          {setTimeoutModelShow(true)}
          {setTimeoutModelOneMinuteShow(true)}
          {setIsTimeUp(true)}
        </>
      );
    } else {
      // Check for one-minute warning
      if (hours === 0 && minutes === 1 && seconds === 0) {
        setTimeoutModelOneMinuteShow(true);
      }

      return (
        <span>
          {formatTime(hours)} : {formatTime(minutes)} : {formatTime(seconds)}
        </span>
      );
    }
  };

  // Prevent timer from running during video upload
  if (isVideoUploading) {
    return null;
  }

  return (
    <Countdown
      date={Date.now() + totalExamTime}
      renderer={renderer}
      autoStart={true}
      controlled={false}
      onComplete={() => {
        if (isVideoInterviewTest && isRecordingDone) {
          setTimeoutModelShow(true);
          setTimeoutModelOneMinuteShow(true);
          setIsTimeUp(true);
        }
      }}
    />
  );
}
